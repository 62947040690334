import React from "react";
import { Title, Text } from "../../utils/system";
import { motion } from "framer-motion";
import styled from "styled-components";
import { color, position, flexbox, layout, space, border } from "styled-system";

const Header = ({ title, subtitle }) => {
  return (
    <Motion flexDirection="column" py={5}>
      <Title fontSize={[5, 6, 8]} m={0} textAlign="center">
        {title}
      </Title>
      <Text mt={2} width={["80%", "50%"]} textAlign="center">
        {subtitle}
      </Text>
      {/* <Flex
        bg="black"
        color="white"
        px={3}
        py={3}
        mt={4}
        justifyContent="center"
        alignItems="center"
      >
        <Label m={0}>Contact Us -></Label>
      </Flex> */}
    </Motion>
  );
};

export default Header;

const Motion = styled(motion.div)(
  {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  color,
  position,
  flexbox,
  layout,
  space,
  border
);
