import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Blocks from "../components/Blocks";
import styled from "styled-components";
import {
  layout,
  space,
  display,
  color,
  flexbox,
  position,
} from "styled-system";
import { motion, AnimatePresence } from "framer-motion";

import { GatsbyImage } from "gatsby-plugin-image";
import { Grid, Title, Flex, Text, Box, Label } from "../utils/system";
import Header from "../components/Header";
import { useEffect } from "react";

// import Video from "../components/Video";

const About = ({ data }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [content, setContent] = useState(null);
  const { title, subtitle, people, _rawContent } = data.sanityAbout;

  return (
    <Layout>
      <Header title={title} subtitle={subtitle} />
      {/* <Video url="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" /> */}
      <Flex mb={[3, 5]}>
        <Flex width={["90%", "70%"]}>
          <Blocks blocks={_rawContent} />
        </Flex>
      </Flex>

      <Flex>
        <Flex flexDirection="column" width={["90%"]} mb={6}>
          <Grid
            gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
            gridGap={4}
          >
            {people.map((item) => (
              <motion.div
                layoutId={item.name}
                onClick={() => {
                  setSelectedId(item.name);
                  setContent(item);
                  document.querySelector("body").style.position = "fixed";
                }}
              >
                <Box mb={4}>
                  <GatsbyImage
                    image={item.image.asset.gatsbyImageData}
                    alt={item.name}
                  />
                  <Title mb={1}>{item.name}</Title>
                  <Label color="pink" m={0}>
                    {item.role}
                  </Label>
                </Box>
              </motion.div>
            ))}
          </Grid>
          <AnimatePresence>
            {selectedId && (
              <Bio
                animate={{ opacity: 1 }}
                layoutId={selectedId}
                position="fixed"
                top={0}
                left={0}
                width={["100vw"]}
                height={["100vh"]}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
                style={{ zIndex: 10 }}
              >
                <Flex flexDirection="row" alignItems="flex-start">
                  <Box
                    position="fixed"
                    top={0}
                    left={0}
                    height="100vh"
                    width={["100vw", "100vw", "50vw"]}
                    display={["none", "none", "block"]}
                  >
                    <GatsbyImage
                      image={content.image.asset.gatsbyImageData}
                      alt={content.name}
                      objectFit="cover"
                    />
                  </Box>
                  <Flex
                    position="fixed"
                    top={0}
                    left={[0, 0, "50vw"]}
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    bg="white"
                    style={{ overflowY: "scroll" }}
                    height="100vh"
                    width={["100vw", "100vw", "50vw"]}
                  >
                    <Box p={[3, 4]}>
                      <Title mb={1} mt={0}>
                        {content.name}
                      </Title>
                      <Label color="pink" m={0}>
                        {content.role}
                      </Label>
                      <Blocks blocks={content._rawBio} />
                      <motion.button
                        onClick={() => {
                          setSelectedId(null);
                          document.querySelector("body").style.position =
                            "relative";
                        }}
                        style={{
                          position: "fixed",
                          top: 20,
                          right: 20,
                          width: 50,
                          height: 50,
                          background: "rgb(255,34,151)",
                          borderRadius: 25,
                          border: "none",
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 51 51"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M48.8344 0.0441895L50.9557 2.16551L2.1653 50.9559L0.0439835 48.8346L48.8344 0.0441895Z"
                            fill="white"
                          />
                          <path
                            d="M0.0439453 2.16553L2.16527 0.044207L50.9556 48.8346L48.8343 50.9559L0.0439453 2.16553Z"
                            fill="white"
                          />
                        </svg>
                      </motion.button>
                    </Box>
                  </Flex>
                </Flex>
              </Bio>
            )}
          </AnimatePresence>

          {/* {people &&
              people.map((item, key) => <Team data={item} key={key} />)} */}
        </Flex>
      </Flex>
    </Layout>
  );
};

export default About;

const Bio = styled(motion.div)(
  {
    // position: "fixed",
  },
  space,
  layout,
  display,
  color,
  flexbox,
  position
);

export const pageQuery = graphql`
  query About {
    sanityAbout {
      title
      subtitle
      _rawContent
      people {
        name
        role
        _rawBio
        image {
          asset {
            gatsbyImageData(aspectRatio: 0.8)
          }
        }
      }
    }
  }
`;
